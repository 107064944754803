var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"#303030"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('drawer')}}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"brown","to":{ name: 'adminApiEdit', params: { componentId: 'create' } }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Crear API Endpoint ")],1),_c('v-btn',{attrs:{"depressed":"","color":"brown","loading":_vm.saving,"disabled":!_vm.dirty},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("save")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v("Guardar Cambios")])],1)],1),(_vm.$apollo.loading)?_c('Loading'):_c('v-container',{staticClass:"white--text main-text",staticStyle:{"max-width":"960px","overflow":"auto","max-height":"calc(100vh - 98px)"}},[_c('h1',{staticClass:"text-h4"},[_vm._v(" Configuración de API para "+_vm._s(_vm.environmentApiConfig.name)+" ")]),_c('h2',{staticClass:"overline"},[_vm._v("Básico")]),_c('v-container',{staticClass:"mb-5",attrs:{"fluid":""}},[_c('v-switch',{attrs:{"color":"brown","label":"Habilitar API para este entorno"},model:{value:(_vm.environmentApiConfig.enableApi),callback:function ($$v) {_vm.$set(_vm.environmentApiConfig, "enableApi", $$v)},expression:"environmentApiConfig.enableApi"}}),_c('v-switch',{attrs:{"color":"brown","label":"Habilitar documentación automática"},model:{value:(_vm.environmentApiConfig.enableApiDocs),callback:function ($$v) {_vm.$set(_vm.environmentApiConfig, "enableApiDocs", $$v)},expression:"environmentApiConfig.enableApiDocs"}})],1),_c('h2',{staticClass:"overline"},[_vm._v("Autenticación")]),_c('v-container',{staticClass:"mb-5",attrs:{"fluid":""}},[_c('Fields',{attrs:{"environmentVariables":{ environmentId: _vm.environmentId },"schema":{
          apiAuthType: {
            type: 'select',
            label: 'Tipo de autenticación',
            fieldOptions: {
              options: _vm.availableAuthTypes
            },
            newDesign: true,
            optional: true
          }
        }},model:{value:(_vm.environmentApiConfig),callback:function ($$v) {_vm.environmentApiConfig=$$v},expression:"environmentApiConfig"}}),(_vm.environmentApiConfig.apiAuthType === 'manualTokens')?[_c('v-list',[_vm._l((_vm.environmentApiConfig.apiTokens),function(token){return _c('v-list-item',{key:token.token,attrs:{"href":"#"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(token.token)}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeToken(token)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)}),_c('v-list-item',{on:{"click":_vm.addToken}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("add")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Generar Token")])],1)],1)],2)]:_vm._e(),(_vm.environmentApiConfig.apiAuthType === 'authScript')?[_c('MonacoEditor',{ref:"editor",staticClass:"code-editor",attrs:{"theme":"vs-dark","language":"javascript"},on:{"editorDidMount":_vm.editorDidMount},model:{value:(_vm.environmentApiConfig.apiAuthScript),callback:function ($$v) {_vm.$set(_vm.environmentApiConfig, "apiAuthScript", $$v)},expression:"environmentApiConfig.apiAuthScript"}})]:_vm._e()],2)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"bottom":"","timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"color":_vm.snackbarColor ? '' : 'primary',"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" OK ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }