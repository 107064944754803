


































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import { PaginatedQueryResult, ApiEndpoint } from '@/models'

@Component({
  apollo: {
    apiEndpoints: {
      query() {
        return gql`
          query getApiEndpoints($environmentId: ID) {
            apiEndpoints(environmentId: $environmentId) {
              items {
                _id
                name
                path
                method
                roles
              }
            }
          }
        `
      },
      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class ApiEditorSidebar extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: Number }) lastUpdated?: number

  apiEndpoints: Partial<PaginatedQueryResult<ApiEndpoint>> = {}
  searchQuery = ''

  get apiEndpointResults() {
    if (!this.apiEndpoints.items) return []
    const displayResult = this.apiEndpoints.items.map((apiEndpoint) => ({
      ...apiEndpoint,
      name: apiEndpoint.name,
      title: apiEndpoint.name,
      subtitle: apiEndpoint.path,
      methodColor: this.getColorForMethod(apiEndpoint.method),
      icon: (apiEndpoint.roles || []).length ? 'lock' : 'public'
    }))
    const searcher = new FuzzySearch(displayResult || [], [
      'name',
      'title',
      'path',
      '_id'
    ])
    return searcher.search(this.searchQuery ?? '')
  }

  @Watch('lastUpdated')
  refreshApiEndpointList() {
    this.$apollo.queries.apiEndpoints.refresh()
  }

  getColorForMethod(method: string) {
    return (
      {
        GET: 'cyan',
        HEAD: 'blue-grey',
        POST: 'green',
        PUT: 'purple',
        DELETE: 'red',
        PATCH: 'pink',
        ALL: 'brown'
      } as Record<string, string>
    )[method]
  }
}
