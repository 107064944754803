



































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment } from '@/models'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import Chance from 'chance'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'

const chance = new Chance()

@Component({
  components: {
    Loading,
    Fields,
    MonacoEditor,
    ComponentEditorDialog
  },
  apollo: {
    savedEnvironmentApiConfig: {
      query: gql`
        query ($environmentId: ID) {
          savedEnvironmentApiConfig: environment(
            environmentId: $environmentId
          ) {
            _id
            name
            enableApi
            enableApiDocs
            apiAuthType
            apiAuthScript
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class ApiEditorHome extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: Boolean }) isDrawerOpen?: boolean

  saving = false

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false

  snackbar = false
  snackbarText = ''
  snackbarColor = ''

  savedEnvironmentApiConfig: Partial<Environment> | null = null
  environmentApiConfig: Partial<Environment> = {}

  authType = ''
  availableAuthTypes = [
    { label: 'Sin autenticación', value: '' },
    /* {
      label: 'Tokens generados por el usuario',
      value: 'userTokens'
    },
    { label: 'Tokens manuales', value: 'manualTokens' },
    */
    { label: 'Script de autenticación', value: 'authScript' }
  ]

  @Watch('savedEnvironmentApiConfig')
  update(newData: Environment) {
    this.$set(
      this,
      'environmentApiConfig',
      _cloneDeep(this.savedEnvironmentApiConfig)
    )
  }

  get dirty() {
    return !_isEqual(this.environmentApiConfig, this.savedEnvironmentApiConfig)
  }

  async save() {
    if (this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentId: ID, $api: EnvironmentApiInput) {
            setEnvironmentApi(environmentId: $environmentId, api: $api) {
              _id
              name
              enableApi
              enableApiDocs
              apiAuthType
              apiAuthScript
            }
          }
        `,
        // Parameters
        variables: {
          environmentId: this.environmentId,
          api: {
            enableApi: this.environmentApiConfig.enableApi,
            enableApiDocs: this.environmentApiConfig.enableApiDocs,
            apiAuthType: this.environmentApiConfig.apiAuthType,
            apiAuthScript: this.environmentApiConfig.apiAuthScript
          }
        }
      })

      this.savedEnvironmentApiConfig = result.data.setEnvironmentApi
      this.snackbar = true
      this.snackbarText = 'Configuración guardada con éxito!'
    } catch (e) {
      this.$emit('error', e)
      this.snackbar = true
      this.snackbarText = 'Error: ' + e.message
      this.snackbarColor = 'error'
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  addToken() {
    const newToken = {
      token: chance.string({ alpha: true, numeric: true, length: 64 })
    }

    if (!this.environmentApiConfig.apiTokens) {
      this.environmentApiConfig.apiTokens = [newToken]
      return
    }

    this.environmentApiConfig.apiTokens.push(newToken)
    this.$forceUpdate()
  }

  removeToken(token: string) {}

  editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })

    // Actions
    editor.addAction({
      id: 'save',
      label: 'Guardar Cambios',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => this.save()
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
